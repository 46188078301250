<template class="preview">
  <div>
    <div class="preview-web">
      <div
        class="preview-frame"
        :class="selectedTheme"
      >
        <HeaderLoader 
          v-if="!(loadingFinished || layout.header)"
          class="app-header"
        />
        <div
          v-else-if="layout.header && layout.header.show"
          class="app-header"
        >
          <b-navbar
            :class="{
              'logo-right': layout.header.logo.position === 'right'
            }"
          >
            <template #brand>
              <b-navbar-item
                :href="layout.logoOptions && layout.logoOptions.enableNavigation ? (layout.logoOptions.link === 'module' ? `${origin}/${layout.logoOptions.deployedId}` : layout.logoOptions.remoteUrl) : 'javascript:void(0)'"
              >
                <img
                  v-if="layout.header.logo.show"
                  :src="layout.logo"
                  class="main-logo"
                  alt="Logo"
                  :class="[
                    layout.logo && layout.logo.length && layout.logo.split('.').slice(-1)[0] === 'svg' ? 'is-svg' : ''
                  ]"
                >
              </b-navbar-item>
            </template>
            <template
              #end
            >
              <div
                v-if="layout.header && layout.header.navigation.show"
                class="is-flex"
              >
                <b-navbar-item
                  v-for="(item, index) in layout.header.navigation.items"
                  :key="index"
                  :active="$route.params.moduleId === item.deployedId"
                  :href="item.link === 'module' ? `${origin}/${item.deployedId}` : item.remoteUrl"
                >
                  {{ item.label }}
                </b-navbar-item>
              </div>
              <div
                v-if="layout.header && layout.header.signout && layout.header.signout.show"
                class="ml-2 is-flex is-align-items-center"
              >
                <b-navbar-item>
                  <LogoutButton
                    :dropdown-options="layout.appOptions"
                    position="is-bottom-left"
                  />
                </b-navbar-item>
              </div>
            </template>
          </b-navbar>
        </div>
        <div
          v-if="errorFieldList.length"
          class="error-navigator"
        >
          <div class="is-flex is-justify-content-center is-align-items-center">
            <b-button
              icon-right="chevron-left"
              type="is-danger"
              size="is-small"
              class="button-left"
              :disabled="currentScrolledErrorField === 0"
              @click.native.stop="scrollToErrorField(currentScrolledErrorField - 1)"
            />
            <b-field class="mb-0">
              <b-tag type="is-danger">
                <div class="steps ml-1">
                  {{ currentScrolledErrorField + 1 }} / {{ errorFieldList.length }}
                </div>
              </b-tag>
            </b-field>
            <b-button
              icon-right="chevron-right"
              type="is-danger"
              size="is-small"
              class="button-right"
              :disabled="currentScrolledErrorField === errorFieldList.length - 1"
              @click.native.stop="scrollToErrorField(currentScrolledErrorField + 1)"
            />
          </div>
        </div>
        <div
          class="columns app-main-section"
          :class="{
            'no-sidebar': !(layout.sidebar && layout.sidebar.show)
          }"
        >
          <div
            v-if="layout.sidebar && layout.sidebar.show && layout.sidebar.position === 'left'"
            class="app-sidebar"
            :class="isSidebarOpened ? 'column is-3' : 'p-0'"
          >
            <div class="is-flex is-justify-content-flex-end">
              <b-button
                type="is-primary"
                :icon-right="isSidebarOpened ? 'arrow-left' : 'arrow-right'"
                class="sidebar-toggle-btn"
                :class="isSidebarOpened ? '' : 'sidebar-collapsed'"
                @click="isSidebarOpened = !isSidebarOpened"
              />
            </div>
            <b-sidebar
              v-model="isSidebarOpened"
              position="static"
              type="is-white"
            >
              <div>
                <b-menu>
                  <img
                    v-if="layout.sidebar.logo && layout.sidebar.logo.show"
                    :src="layout.logo"
                    style="max-width: 250px!important;"
                    alt="Logo"
                  >
                  <b-menu-list>
                    <b-menu-item
                      v-for="(item, index) in layout.sidebar.navigation.items"
                      :key="index"
                      :active="$route.params.moduleId === item.deployedId"
                      :label="item.label"
                      tag="a"
                      :href="item.link === 'module' ? `${origin}/${item.deployedId}` : item.remoteUrl"
                    />
                  </b-menu-list>
                </b-menu>
                <div
                  v-if="layout.sidebar && layout.sidebar.signout && layout.sidebar.signout.show"
                  tag="div"
                >
                  <LogoutButton
                    :dropdown-options="layout.appOptions"  
                  />
                </div>
              </div>
            </b-sidebar>
          </div>
          <div
            class="column app-content"
            :class="[
              layout.sidebar && layout.sidebar.show && isSidebarOpened ? 'is-9' : 'is-12',
              isSidebarOpened ? '' : 'sidebar-collapsed'
            ]"
          >
            <div
              v-if="loadingFinished"
              class="preview-web-content content"
            >
              <div
                style="padding-bottom: 50px !important;"
              >
                <div v-if="response.sections">
                  <div
                    v-for="(section, sectionIndex) in response.sections"
                    :key="sectionIndex"
                  >
                    <form
                      v-if="section.show"
                      @submit.prevent="goNext(false)"
                    >
                      <div class="columns">
                        <div
                          v-for="(num, index) in section.fields"
                          :key="index"
                          class="column"
                          :class="section.columns === '1' ? 'is-12' : section.columns === '2' ? 'is-6' : 'is-4'"
                        >
                          <div
                            v-for="(field, fieldIndex) in section.fields[index]"
                            :key="fieldIndex"
                          >
                            <b-field
                              v-if="field.show && field.type !== 'hidden'"
                              class="column"
                              :class="field.properties.basic.width"
                              :type="errors[field.id] || ibanError[field.id] ? 'is-danger' : 'is-default'"
                              :message="errors[field.id] || ibanError[field.id] || ''"
                            >
                              <template
                                v-if="field.properties.basic.hasLabel"
                                #label
                              >
                                {{ getTranslation(`${field.id}_label`, field.properties.basic.label) }} <span
                                  v-if="typeof field.properties.validation.isRequired !== 'undefined' && field.properties.validation.isRequired"
                                >*</span>
                                <b-tooltip
                                  v-if="field.properties.basic.hasInfoTooltip"
                                  type="is-dark"
                                  multilined
                                  position="is-right"
                                  size="is-large"
                                >
                                  <b-icon icon="help-circle-outline" />
                                  <template #content>
                                    {{ field.properties.basic.infoTooltipText }}
                                  </template>
                                </b-tooltip>
                              </template>

                              <FieldRenderer 
                                :field="field"
                                :values="variablesValues"
                                :errors="errors"
                                @blur="handleFieldBlur(field)"
                                @update="handleFieldUpdate(field, $event)"
                                @error="handleFieldErrors(field, $event)"
                                @success="handleFieldErrors(field, $event, false)"
                              />
                            </b-field>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="app-loader"
            >
              <SkeletonLoaderBuilder 
                :loader-template-meta="loaderTemplate"
              />
            </div>
          </div>
          <div
            v-if="layout.sidebar && layout.sidebar.show && layout.sidebar.position === 'right'"
            class="app-sidebar column is-3"
          >
            <b-menu>
              <img
                v-if="layout.sidebar.logo.show"
                :src="layout.logo"
                style="max-width: 250px!important;"
                alt="Logo"
              >
              <b-menu-list>
                <b-menu-item
                  v-for="(item, index) in layout.sidebar.navigation.items"
                  :key="index"
                  :label="item.label"
                  tag="a"
                  :href="item.link === 'module' ? `${origin}/${item.deployedId}` : item.remoteUrl"
                />
              </b-menu-list>
            </b-menu>
          </div>
        </div>
        <FooterLoader 
          v-if="!(loadingFinished || layout.footer)"
          class="app-footer"
        />
        <div
          v-else-if="layout.footer && layout.footer.show"
          class="app-footer"
        >
          <div
            v-if="layout.footer.copyRight.show"
            class="has-text-centered"
          >
            <p class="is-size-7">
              {{ layout.footer.copyRight.text }}
            </p>
          </div>
          <div class="columns">
            <div class="column">
              <img
                v-if="layout.footer.logo.show"
                :src="layout.logo"
                style="max-width: 250px!important;"
                alt="Logo"
              >
              <b-menu v-else-if="layout.footer.navigation.show && !layout.footer.logo.show">
                <b-menu-list>
                  <b-menu-item
                    v-for="(item, index) in layout.footer.navigation.items"
                    :key="index"
                    :label="item.label"
                    tag="a"
                    :href="item.link === 'module' ? `${origin}/${item.deployedId}` : item.remoteUrl"
                  />
                </b-menu-list>
              </b-menu>
            </div>
            <div class="column">
              <b-menu v-if="layout.footer.navigation.show && layout.footer.logo.show">
                <b-menu-list>
                  <b-menu-item
                    v-for="(item, index) in layout.footer.navigation.items"
                    :key="index"
                    :label="item.label"
                    tag="a"
                    :href="item.link === 'module' ? `${origin}/${item.deployedId}` : item.remoteUrl"
                  />
                </b-menu-list>
              </b-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment-timezone';
import { isEqual, uniqWith } from 'lodash';
import { defineAsyncComponent } from '@vue/composition-api';
import { mapState } from 'pinia';
import { runModuleService } from '@/services/interpreter-service/interpreterModuleRequests';
import { fetchDeploymentsServiceByModuleAndEnvironment } from '@/services/build-service/deploymentRequests';
import FormRenderer from '@/helpers/FormRenderer.js';
import FieldRenderer from '@/components/FieldRenderer.vue';
import { useApplicationStore } from '@/store/applicationStore';
import { useVariableStore } from '@/store/variableStore';
import { useWorkflowStore } from '@/store/workflowStore';
import { delay, nextFrame } from '@/helpers/Utils';
import SkeletonLoaderBuilder from '@/components/skeleton-loader/SkeletonLoaderBuilder.vue';
import HeaderLoader from '@/components/skeleton-loader/HeaderLoader.vue';
import FooterLoader from '@/components/skeleton-loader/FooterLoader.vue';
import { createFormValidator } from '@/lib/nuclicore-core';
import { useModuleSettingsStore } from '@/store/moduleSettingsStore';
import { useLocalizationStore } from '@/store/localizationStore';

const applicationStore = useApplicationStore();
const variableStore = useVariableStore();
const workflowStore = useWorkflowStore();
const moduleSettingsStore = useModuleSettingsStore();
const localizationStore = useLocalizationStore();

const formValidator = createFormValidator();

export default {
    components: {
        LogoutButton: defineAsyncComponent(() => import('@/components/LogoutButton.vue')),
        SkeletonLoaderBuilder,
        HeaderLoader,
        FooterLoader,
        FieldRenderer
    },
    beforeRouteLeave(from, to, next) {
        next();
    },
    metaInfo () {
        const appLinks = [];
        if (this.favIconUrl) {
            appLinks.push({ rel: 'icon', href: this.favIconUrl });
        }
        return {
            title: this.appTitle,
            link: appLinks
        };
    },
    data: () => ({
        response: {},
        docsContentType: 'application/pdf, plain/text, plain/csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingm',
        imageContentType: 'image/png, image/jpg, image/jpeg',
        currentNodeId: 2,
        previousNodeId: null,
        containerClasses: '',
        session: null,
        contents: {},
        consoleMessages: [],
        loadingFinished: false,
        theme: 'Default',
        errors: {},
        errorFieldList: [],
        currentScrolledErrorField: null, 
        date: new Date(),
        firstLoad: true,
        selectedTheme: 'Krose',
        layout: {},
        autoCompleteSelected: null,
        baseUrl: '',
        tariff: {},
        sign: {
            option:{
                penColor:'rgb(0, 0, 0)',
                backgroundColor:'rgb(255,255,255)'
            },
            dataUrl:''
        },
        numberOfSeconds: 0,
        counter: null,
        ibanError: {},
        nodeName: '',
        nodeStack: [],
        origin: window.location.origin,
        isSidebarOpened: true,
        loaderTemplate: [
            { type: 'input' },
            { type: 'radiobox' },
            { type: 'input' },
            { type: 'checkbox' },
            { type: 'text-area' }
        ]
    }),
    computed: {
        appTitle () {
            return this.layout?.title || '';
        },
        favIconUrl () {
            return this.layout?.favIcon || null;
        },
        isFormValid () {
            return this.errors ? Object.values(this.errors).filter(error => error).length === 0 : true;
        },
        errorFieldElements () {
            const fieldElements = this.errorFieldList.map(fieldId => {
                const element = document.querySelector(`[data-field-id="${fieldId}"]`);
                return element;
            }).filter(element => element);
            return fieldElements;
        },
        ...mapState(useVariableStore, ['variablesValues'])
    },
    watch: {
        variablesValues () {
            if(applicationStore.shouldUpdateForm){
                this.update();
            }else{
                applicationStore.shouldUpdateForm = true;
            }
        }
    },
    async mounted() {
        window.addEventListener('popstate',this.handleBrowserNavigation);
        this.baseUrl = window.env.VUE_APP_DOMAIN;
        await Promise.all([
            this.getTheme(),
            this.loadModuleSettings()
        ]);
        localizationStore.setLanguage(this.$route.query.lang);
        this.runModule(true);
        this.origin = window.location.origin;
    },
    
    methods: {
        async handleBrowserNavigation($event) {
            const execNode = this.nodeStack.findIndex(v => v.custom_url_name === this.$route?.params?.customUrl);
            if (execNode >= 0) {
                this.previousNodeId = this.nodeStack[execNode].id;
                this.goBack(false);
            } else {
                this.runModule(true);
            }
        },
        updateAddress(field, payload) {
            const { id } = field;
            const option = payload.value;
            if(option?.address){
                const fields = [
                    { label: 'street', value: option.address?.street },
                    { label: 'zipCode', value: option.address?.postalCode },
                    { label: 'city', value: option.address?.city },
                    { label: 'country', value: option.address?.country },
                    { label: 'number', value: option.address?.houseNumber },
                    { label: 'state', value: option.address?.state },
                    { label: 'district', value: option.address?.district }
                ];
                fields.forEach(field => {
                    const variableId = { type: 'variable', value: `${field.label}_${id}` };
                    variableStore.updateVariableById(variableId, {
                        value: field.value
                    });
                });
            }
        },
        updateSign (field, payload) {
            const variableId = { type: 'variable', value: field.id };
            variableStore.updateVariableById(variableId, {
                value: payload.value.data
            });
        },
        async getTheme() {
            try {
                await applicationStore.fetchDeploymentInfo(this.$route.params.moduleId);
                this.selectedTheme = applicationStore.deploymentInfo?.theme_name || 'Krose';

                // Need to store guarded module deployment id
                if (applicationStore.deploymentInfo.guarded_module_id) {
                    const moduleLocalStorage = JSON.parse(localStorage.getItem(this.$route.params.moduleId)) || {};
                    const params = {
                        module_id: applicationStore.deploymentInfo.guarded_module_id,
                        environment_id: applicationStore.deploymentInfo.environment_id
                    };
                    const {data: authModule } = await fetchDeploymentsServiceByModuleAndEnvironment(params);
                    moduleLocalStorage.auth_module_deployment_id = authModule.data.id;
                    moduleLocalStorage.environment_id = authModule.data.environment_id;
                    localStorage.setItem(this.$route.params.moduleId, JSON.stringify(moduleLocalStorage));
                }
            } catch (err) {
                console.log(err);
            }
        },
        stopCounting(name) {
            clearTimeout(this.counter);
            if (name) {
                axios.post(window.env.VUE_APP_APP_SERVICE_DOMAIN + '/analytics/page', {
                    name: name,
                    numberOfSeconds: this.numberOfSeconds,
                    session_id: this.session
                });
            }
        },
        startCounting(name) {
            this.nodeName = name;
            this.numberOfSeconds = 0;

            this.counter = setInterval(() => {
                this.numberOfSeconds++;
            }, 1000);
        },
        async runModule (onLoad = false) {
            try {
                //this.stopCounting(this.nodeName);
                const sessionPayload = {
                    preview: false
                };

                sessionPayload.deletedUploads = applicationStore.getDeletedUploads;

                // Local Storage
                let currentSession = JSON.parse(localStorage.getItem(this.$route.params.moduleId)) || {};
                if (currentSession?.module_auth_token) {
                    sessionPayload.module_auth_token = currentSession?.module_auth_token;
                }
                const authModuleSession = JSON.parse(localStorage.getItem(currentSession.auth_module_deployment_id)) || {};
        
                if (authModuleSession?.module_auth_token && authModuleSession?.session_id) {
                    sessionPayload.auth_module_session = authModuleSession.session_id;
                }
                // if (authModuleSession?.module_auth_token && currentSession?.current_node_id && currentSession?.session_id) {
                //     sessionPayload.node_id = currentSession.current_node_id;
                //     sessionPayload.session = currentSession.session_id;
                //     sessionPayload.back = true;
                // }

                const query = this.extractURLQuery();

                if (this.$route.params?.customUrl) {
                    sessionPayload.customUrl = this.$route.params.customUrl;
                }

                const headers =  {};
                if (authModuleSession?.module_auth_token) {
                    headers.ModuleAuthorization = `Bearer ${authModuleSession.module_auth_token}`;
                }

                const { data: { data: moduleData, home_module } } = await runModuleService(
                    this.$route.params.moduleId,
                    sessionPayload,
                    query,
                    headers
                );
                
                applicationStore.executionResult = moduleData;
                this.fetchScreenWorkflows(moduleData);

                if (home_module) {
                    this.redirectToHomeModule(home_module);
                }

                this.currentNodeId = moduleData.current_id;
                this.previousNodeId = moduleData.previous_id;
                this.session = moduleData.session;

                const nodeInfo = {
                    id: moduleData.current_id
                };

                let isAuthModule = false;
                if (!moduleData.guarded_module_id && (currentSession.module_auth_token || currentSession.guarded_module_deployment_id)) {
                    isAuthModule = true;
                }

                if (moduleData.guarded_module_id) {
                    // Save current state in localstorage
                    // this.saveModuleUserCurrentSessionToLocalStorage(currentSession, moduleData);
                } else if (!isAuthModule) {
                    localStorage.setItem(this.$route.params.moduleId, JSON.stringify({}));
                }

                if (moduleData.theme === 'Dark purple') {
                    this.theme = 'Purple';
                }
                this.parseActionsValue(moduleData.values);

                moduleData?.response?.forEach(response => {
                    if (response?.status === 1203) {
                        window.location.href = response.data.url;
                    } else if (response?.status === 1005 ) {
                        this.firstLoad = false;
                        this.parseLayout(response.data.layout_data);
                        //this.startCounting(response.data.name);
                        this.addCustomURLToLocation(response.data?.custom_url_name);
                        nodeInfo.custom_url_name = response.data?.custom_url_name;

                        response.data?.sections.forEach(section => {
                            section.fields.forEach(columns => {
                                columns.forEach(field => {
                                    if (field.type === 'date') {
                                        field.value = moment(field.value).toDate();
                                    }
                                });
                            });
                        });
                        this.response = response.data;
                    }
                });
                this.nodeStack = [];
                this.addCurrentNodeToStack(nodeInfo);
                await nextFrame();
                await this.loadTranslations(onLoad);
                await this.update(onLoad);
                this.loadingFinished = true;
                applicationStore.deletedUploads = {};
            } catch (err) {
                console.error(err);
                if (err?.response?.status === 401) {
                    this.handleUnauthorizedResponse(err.response);
                }
            }
        },
        addCustomURLToLocation(customUrl, pQuery = {}) {
            const query = {...this.$route.query, ...pQuery};
            if (!customUrl || customUrl === '') {
                this.$router.push({ name: 'Main', params: { moduleId: this.$route.params.moduleId }, query });
                return;
            }
            // named route
            this.$router.push({ name: 'MainCustomURL', params: { moduleId: this.$route.params.moduleId, customUrl }, query });
        },

        extractURLQuery () {
            const query = {
                ...this.$route.query
            };
            if (!this.$route.query.lang && localizationStore.currentLanguage?.queryParam) {
                query.lang = localizationStore.currentLanguage.queryParam;
            }
            return query;
        },

        parseLayout(data) {
            if (data) {
                this.layout = data;
            } else {
                this.layout = {};
            }
        },
        changeDate(field, payload) {
            const { id } = field;
            let { value } = payload;
            let updatedDate = null;
            if (value) {
                if (value instanceof Date) {
                    value = value.toISOString();
                }
                updatedDate = moment.utc(value).toDate();
            }
            if (updatedDate && !field.properties.basic?.hasTimePicker) {
                // if date picker has no time picker set time values to 0 i.e start of day
                updatedDate.setHours(0, 0, 0, 0);
            }
            updatedDate = updatedDate?.toISOString() || '';
            const variableId = { type: 'variable', value: id };
            variableStore.updateVariableById(variableId, {
                value: updatedDate
            });
        },
        updatePaymentStatus (field, payload) {
            const { id } = field;
            const variableId = { type: 'variable', value: id };
            variableStore.updateVariableById(variableId, {
                value: payload.value
            });
        },
        updateCheckboxValues (field, payload) {
            const { id } = field;
            const choice = payload.value;
            const selectedOptions = variableStore.variables[id]?.value ? variableStore.variables[id].value.split(',') : [];
            const labelsId = `${id}_selected_label`;
            const selectedOptionLabels = variableStore.variables[labelsId]?.value ? variableStore.variables[labelsId].value.split(',') : [];
            const index = selectedOptions.indexOf(choice);
            if (index !== -1) {
                selectedOptions.splice(index, 1);
                selectedOptionLabels.splice(index, 1);
            } else {
                selectedOptions.push(choice);
                selectedOptionLabels.push(payload.label);
            }
            const valueVariableId = { type: 'variable', value: id };
            variableStore.updateVariableById(valueVariableId, {
                value: selectedOptions.join(',')
            });
            const labelVariableId = { type: 'variable', value: labelsId };
            variableStore.updateVariableById(labelVariableId, {
                value: selectedOptionLabels.join(',')
            });
        },
        showMobile(show) {
            if (show) {
                this.containerClasses = 'mobile-container';
            } else {
                this.containerClasses = '';
            }
        },
        parseValues(onLoad = false) {
            this.response.sections.forEach(section => {
                section.fields.forEach(columns => {
                    columns.forEach(field => {
                        if (field.type === 'date') {
                            field.value = moment(field.value).toDate();
                        } else if (field.type !== 'data-table' && field.type !== 'hidden') {
                            if (field.value && onLoad) {
                                const variableId = { type: 'variable', value: field.id };
                                variableStore.updateVariableById(variableId, {
                                    value: field.value
                                });
                            }
                        }
                    });
                });
            });
            this.firstLoad = false;
        },
        parseActionsValue(values) {
            if(Object.keys(values).length === 0) {
                variableStore.variables = {};
                variableStore.intialVariables = {};
            }
            for (const key in values ) {
                this.$set(variableStore.intialVariables, key, { ...(values[key] || {}) });
                variableStore.updateVariableById({ 
                    type: 'variable', 
                    value: key 
                }, { ...values[key] });
            }
        },
        async update(onLoad = false) {
            this.parseValues(onLoad);
            await delay(200);
            this.response = await FormRenderer.check(this.response, this.variablesValues);
        },
        openSuccessToast(message) {
            if (message) {
                this.$buefy.toast.open({
                    duration: 5000,
                    message,
                    position: 'is-top',
                    type: 'is-success'
                });
            }
        },
        openErrorToast(message = 'There was an unexpected error. Please try again later.') {
            this.$buefy.toast.open({
                duration: 5000,
                message,
                position: 'is-top',
                type: 'is-danger'
            });
        },
        selectTariff(field, payload) {
            const { id } = field;
            const tariff = payload.value;
            const fields = [
                { label: 'title', value: tariff.title },
                { label: 'premium', value: tariff.price },
                { label: 'description', value: tariff.description }
            ];
            fields.forEach(field => {
                const variableId = { type: 'variable', value: `${field.label}_${id}` };
                variableStore.updateVariableById(variableId, {
                    value: field.value
                });
            });
            axios.post(window.env.VUE_APP_APP_SERVICE_DOMAIN + '/analytics/conversion', {
                session_id: this.session,
                conversion: 1,
                tariff
            });
            this.goNext();
        },
        scrollToErrorField (index) {
            index = Math.max(0, index);
            index = Math.min(this.errorFieldElements.length - 1, index);
            this.currentScrolledErrorField = index;
            const fieldElement = this.errorFieldElements[index];
            if (fieldElement) {
                fieldElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }
        },
        async goNext(row = null, reload = true) {
            const form = applicationStore.executionResult?.response?.find(node => node.data.nodeId === applicationStore.currentNodeId);
            if (form?.data) {
                // validate current form values before going next
                const validationResult = await formValidator.validateForm(form.data);
                if (validationResult.errors.length) {
                    this.errors = validationResult.errors.reduce((result, error) => {
                        result[error.id] = error.message;
                        return result;
                    }, {});
                    this.errorFieldList = [...new Set(validationResult.errors.map(error => error.id))];
                    this.scrollToErrorField(0);
                    return false;
                } else {
                    this.errorFieldList = [];
                    this.currentScrolledErrorField = null;
                }
            }

            if (Object.values(this.ibanError).filter(errorMessage => errorMessage).length) {
                return false;
            }
            try {
                //this.stopCounting(this.nodeName);

                // Local Storage
                const currentSession =  JSON.parse(localStorage.getItem(this.$route.params.moduleId)) || {};
                const authModuleSession = JSON.parse(localStorage.getItem(currentSession.auth_module_deployment_id)) || {};

                const headers =  {};
                if (authModuleSession?.module_auth_token) {
                    headers.ModuleAuthorization = `Bearer ${authModuleSession.module_auth_token}`;
                }

                const params = this.extractURLQuery();
                if (row) {
                    params.id = row.ID;
                    this.addCustomURLToLocation(null, {id: row.ID});
                }
                const payloadValues = this.generateValuesPayload();
                if (params?.rspwdtoken) {
                    payloadValues['rspwdtoken'] = {
                        value: params?.rspwdtoken,
                        isValueIgnored: false
                    };
                }
                this.errors = {};
                this.loadingFinished = false;
                this.firstLoad = reload;
                const { data: { data: moduleData, module_auth_token: token, home_module, module_auth_error, message } } = await runModuleService(this.$route.params.moduleId, {
                    values: payloadValues,
                    data: this.response,
                    deletedUploads: applicationStore.getDeletedUploads,
                    node_id: this.currentNodeId,
                    session: this.session,
                    preview: false
                }, params, headers);

                if (message) {
                    this.openSuccessToast(message);
                }

                applicationStore.executionResult = moduleData;

                if (module_auth_error) {
                    throw {module_auth_error};
                }
                this.fetchScreenWorkflows(moduleData);
                this.currentNodeId = moduleData.current_id;
                this.previousNodeId = moduleData.previous_id;
                this.session = moduleData.session;
                this.parseActionsValue(moduleData.values);
                const nodeInfo = {
                    id: moduleData.current_id
                };


                // Save current state in localstorage
                const updatedSession = currentSession? currentSession: {};

                if ((updatedSession?.auth_module_deployment_id) || token) {
                    // Handle End Node
                    // if (moduleData.response.length > 0) {
                    //     updatedSession.current_node_id = moduleData.current_id;
                    //     updatedSession.previous_node_id = moduleData.previous_id;
                    // }
                    updatedSession.session_id = moduleData.session;
                    localStorage.setItem(this.$route.params.moduleId, JSON.stringify(updatedSession));
                }

                if (token) {
                    updatedSession.module_auth_token = token;
                    updatedSession.home_module = home_module;
                    /**
                     * If auth for module deployemnt id(auth_module_deployment_id) is exists then redirect to that module
                     *  */
                    if(updatedSession?.guarded_module_deployment_id) {
                        let query = this.extractURLQuery();
                        const queryString = new URLSearchParams(query).toString();
                        const redirectTo = `${window.location.origin}/${updatedSession.guarded_module_deployment_id}?${queryString}`;
                        location.assign(redirectTo);
                    } else if (!updatedSession?.guarded_module_deployment_id && home_module) {
                        this.redirectToHomeModule(home_module);
                    }

                    // Save auth module token & session
                    delete updatedSession.guarded_module_deployment_id;
                    localStorage.setItem(this.$route.params.moduleId, JSON.stringify(updatedSession));
                }

                moduleData?.response?.forEach(res => {
                    if (res?.status === 1203) {
                        const target = res.data.openInNewTab ? '_blank' : '_self';
                        window.open(res.data.url, target);
                    } else if (res?.status === 1005) {
                        this.parseLayout(res.data.layout_data);
                        this.response = res.data;

                        this.addCustomURLToLocation(res.data?.custom_url_name);
                        nodeInfo.custom_url_name = res.data?.custom_url_name;
                        //this.startCounting(this.response.name);
                    }  else if(res?.data) {
                        this.consoleMessages.push({
                            data: res.data,
                        });
                    }
                });
                await this.loadTranslations();
                await this.update(true);
                this.loadingFinished = true;
                this.addCurrentNodeToStack(nodeInfo);
                applicationStore.deletedUploads = {};
            } catch (err) {
                if (err?.module_auth_error) {
                    this.openErrorToast(err.module_auth_error);
                } else if (err?.response?.data?.errors) {
                    for (let error of err.response.data.errors) {
                        error.message.forEach(message => {
                            this.$set(this.errors, error.id, message);
                        });
                    }
                } else if (err?.response?.status === 401) {
                    this.handleUnauthorizedResponse(err.response);
                } else {
                    this.openErrorToast();
                }
                this.loadingFinished = true;
            }
        },
        async goBack(reload) {
            try {
                const query = this.extractURLQuery();
                this.errors = {};
                this.errorFieldList = [];
                this.currentScrolledErrorField = null;
                this.firstLoad = reload;
                this.loadingFinished = false;

                // Local Storage
                const currentSession =  JSON.parse(localStorage.getItem(this.$route.params.moduleId));
                const authModuleSession = JSON.parse(localStorage.getItem(currentSession.auth_module_deployment_id)) || {};
                const headers =  {};
                if (authModuleSession?.module_auth_token) {
                    headers.ModuleAuthorization = `Bearer ${authModuleSession.module_auth_token}`;
                }

                const { data: { data: moduleData } } = await runModuleService(this.$route.params.moduleId, {
                    values: this.generateValuesPayload(),
                    data: this.response,
                    deletedUploads: applicationStore.deletedUploads,
                    node_id: this.previousNodeId,
                    session: this.session,
                    back: true,
                    preview: false
                }, query, headers);

                applicationStore.executionResult = moduleData;

                this.currentNodeId = moduleData.current_id;
                this.previousNodeId = moduleData.previous_id;
                this.session = moduleData.session;
                const nodeInfo = {
                    id: moduleData.current_id
                };

                //if (moduleData.guarded_module_id && currentSession && currentSession.token) {
                // Save current state in localstorage
                //this.saveModuleUserCurrentSessionToLocalStorage(currentSession, moduleData);
                //}

                moduleData.response.forEach(res => {
                    if (res.status === 1005) {
                        this.parseLayout(res.data.layout_data);
                        this.response = res.data;
                        this.addCustomURLToLocation(res.data?.custom_url_name);
                        nodeInfo.custom_url_name = res.data?.custom_url_name;
                    }
                });
                await this.loadTranslations();
                await this.update(false);
                this.loadingFinished = true;
                this.addCurrentNodeToStack(nodeInfo);
                applicationStore.deletedUploads = {};
            } catch (err) {
                console.error(err);
                if (err.response.status === 401) {
                    this.handleUnauthorizedResponse(err.response);
                }
            }
        },
        async fetchScreenWorkflows (moduleData) {
            try {
                const currentScreen = moduleData.response.find(nodeResult => nodeResult.data.nodeType === 'screen' && moduleData.current_id === nodeResult.data.nodeId);
                if (currentScreen) {
                    const fields = currentScreen.data.sections.flatMap(
                        section => section.fields.flatMap(
                            column => column.filter(field => field?.events?.length)
                        )
                    );
                    if (fields?.length) {
                        const workflowsToBeFetched = fields.flatMap(
                            field => field.events
                                .filter(fieldEvent => fieldEvent.workflow)
                                .map(
                                    fieldEvent => workflowStore.fetchWorkflow(currentScreen.data.nodeId, field.id, fieldEvent.workflow)
                                )
                        );
                        await Promise.all(workflowsToBeFetched);
                    }
                }
            } catch (err) {
                console.error(err);
            }
        },
        async validateField (field) {
            const fieldValidationResults = await formValidator.validateField(field);
            const [lastResult] = fieldValidationResults.slice(-1);
            const errorPayload = {
                message: lastResult?.message[0]
            };
            this.handleFieldErrors(field, errorPayload, fieldValidationResults.length > 0);
        },
        handleFieldErrors (field, payload, isError = true) {
            const errorTarget = field.type === 'iban' ? this.ibanError : this.errors;
            if (isError) {
                this.$set(errorTarget, field.id, payload.message);
                if (!this.errorFieldList.includes(field.id)) {
                    this.errorFieldList.push(field.id);
                }
            } else {
                this.$set(errorTarget, field.id, '');
                this.errorFieldList = this.errorFieldList.filter(id => id !== field.id);
            }
            this.currentScrolledErrorField = Math.min(this.currentScrolledErrorField, this.errorFieldList.length - 1);
        },
        handleFieldBlur (field) {
            if (applicationStore.shouldUseEagerValidations) {
                this.validateField(field);
            }
        },
        async handleFieldUpdate (field, payload) {
            if (field.type === 'grid') {
                field = payload.field;
            }
            const fieldUpdateHandlers = {
                address: this.updateAddress,
                checkbox: this.updateCheckboxValues,
                'data-table': this.handleDataTable,
                date: this.changeDate,
                dropdown: this.updateDropdown,
                'email-input': this.updateFieldValue,
                'e-signature': this.updateSign,
                'file-upload': this.updateFileUploadField,
                'iban': this.updateFieldValue,
                'number-input': this.updateFieldValue,
                'password': this.updateFieldValue,
                'radio-box': this.updateRadioBox,
                'tariff-comparison': this.selectTariff,
                toc: this.updateFieldValue,
                'text-area': this.updateFieldValue,
                'text-input': this.updateFieldValue,
                'payment-gateway': this.updatePaymentStatus,
                'html-editor': this.updateFieldValue,
                'tile': this.updateTileValue,
                'time': this.updateFieldValue,
                'back-btn': this.handleCustomNavigation,
                'next-btn': this.handleCustomNavigation
            };
            const handler = fieldUpdateHandlers[field.type];
            if (handler) {
                handler(field, payload);
            }
            if (applicationStore.shouldUseEagerValidations) {
                await this.validateField(field);
            }
        },
        handleCustomNavigation (field) {
            if (field.type === 'next-btn') {
                this.goNext(false, field.properties.basic.clearStateOnSuccess);
            } else {
                this.goBack(false, field.properties.basic.clearStateOnSuccess);
            }
        },
        updateTileValue(field, payload){
            const { id } = field;
            const option = payload.value;
            this.$set(variableStore.variables, 'id_' + id, option.id);
            this.$set(variableStore.variables, 'label_' + id, option.label);
            const fields = [
                { label: 'id', value: option.id },
                { label: 'label', value: option.label }
            ];
            fields.forEach(field => {
                const variableId = { type: 'variable', value: `${field.label}_${id}` };
                variableStore.updateVariableById(variableId, {
                    value: field.value
                });
            });
        },
        updateFieldValue (field, payload) {
            const variableId = { type: 'variable', value: field.id };
            variableStore.updateVariableById(variableId, {
                value: payload.value
            });
        },
        updateRadioBox (field, payload) {
            const valueVariableId = { type: 'variable', value: field.id };
            variableStore.updateVariableById(valueVariableId, {
                value: payload.value
            });
            const labelVariableId = { type: 'variable', value: `${field.id}_selected_label` };
            variableStore.updateVariableById(labelVariableId, {
                value: payload.label
            });
        },
        handleDataTable (field, payload) {
            if(payload?.values){
                for(const key in payload.values){
                    const variableId = { type: 'variable', value: key };
                    variableStore.updateVariableById(variableId, {
                        value: payload.values[key]
                    });
                }
                this.update(false);
            }else{
                this.goNext(payload);
            }
        },
        updateDropdown (field, payload) {
            if (!field.properties.basic.hasAutocomplete) {
                payload.value = Array.isArray(payload.value) ? payload.value.join(',') : payload.value;
                payload.label = Array.isArray(payload.label) ? payload.label.join(',') : payload.label;
            }
            const valueVariableId = { type: 'variable', value: field.id };
            variableStore.updateVariableById(valueVariableId, {
                value: payload.value
            });
            const labelVariableId = { type: 'variable', value: `${field.id}_selected_label` };
            variableStore.updateVariableById(labelVariableId, {
                value: payload.label
            });
        },
        updateFileUploadField (field, payload) {
            if (field.properties.basic?.multiple) {
                const payloadValues = payload.value.filter(fileName => fileName);
                payload.value = payloadValues.length ? payloadValues : '';
            } else {
                payload.value = payload.value[0];
            }
            const variableId = { type: 'variable', value: field.id };
            variableStore.updateVariableById(variableId, {
                value: payload.value
            });
        },
        saveModuleUserCurrentSessionToLocalStorage(currentSession, moduleData) {
            const updatedSession = {
                ...(currentSession || {}),
                current_node_id : moduleData.current_id,
                previous_node_id : moduleData.previous_id,
                session_id : moduleData.session
            };
            localStorage.setItem(this.$route.params.moduleId, JSON.stringify(updatedSession));
        },
        handleUnauthorizedResponse(resData) {
            if (resData.status == 401) {
                //const errors = ['TokenExpiredError', 'JsonWebTokenError', 'WrongTokenError', 'SyntaxError', 'NotBeforeError'];
                if (resData?.data?.authModule?.deployment_id) {
                    this.redirectToAuthModule(resData.data.authModule);
                } else {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: `${resData.data.message}`,
                        position: 'is-top',
                        type: 'is-danger'
                    });
                }
            }
        },
        redirectToAuthModule(authModule) {
            /**
           * Store guard module deployed id in authmodule local storge
           */
            let authModuleLocalStorage = JSON.parse(localStorage.getItem(authModule.deployment_id)) || {};
            authModuleLocalStorage.guarded_module_deployment_id = this.$route.params.moduleId;
            localStorage.setItem(authModule.deployment_id, JSON.stringify(authModuleLocalStorage));
            let query = this.extractURLQuery();
            const queryString = new URLSearchParams(query).toString();
            const redirectTo = `${window.location.origin}/${authModule.deployment_id}?${queryString}`;
            location.assign(redirectTo);
        },
        redirectToHomeModule(homeModule) {
            let query = this.extractURLQuery();
            const queryString = new URLSearchParams(query).toString();
            const redirectTo = `${window.location.origin}/${homeModule}?${queryString}`;
            location.assign(redirectTo);
        },
        addCurrentNodeToStack(nodeInfo) {
            this.nodeStack.push(nodeInfo);
            this.nodeStack = uniqWith(this.nodeStack, isEqual);
        },
        generateValuesPayload () {
            const payload = {};
            Object.entries(variableStore.variables).forEach(([key, variable]) => {
                const isValueIgnored = variableStore.isVariableIgnored(key);
                // add only updated values
                const shouldAppendValue = key in variableStore.intialVariables ? (variableStore.intialVariables[key].value !== variable.value || variableStore.intialVariables[key].isValueIgnored !== variable.isValueIgnored) : true;
                if (shouldAppendValue) {
                    payload[key] = {
                        value: variable.value,
                        isValueIgnored
                    };
                }
            });
            return payload;
        },
        async loadTranslations (loadGlobalTranslations) {
            localizationStore.setLanguage(this.$route.query.lang);
            const translationsToLoad = [];
            if (loadGlobalTranslations) {
                translationsToLoad.push(
                    localizationStore.loadTranslations({ scope: 'GLOBAL' })
                );
            }
            translationsToLoad.push(
                localizationStore.loadTranslations({
                    screenURI: this.$route.params.customUrl,
                    scope: 'LOCAL'
                })
            );
            await Promise.all(translationsToLoad);
        },
        async loadModuleSettings () {
            await moduleSettingsStore.fetchModuleSettings(this.$route.params.moduleId);
        },
        getTranslation (...params) {
            return localizationStore.getTranslation(...params);
        }
    }
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz&display=swap');

html {
  overflow-y: auto !important;
}

.tariff-description {
  * {
    text-align: left;
  }
}

.preview-web {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.preview-frame {
  max-width: 2100px;
  width: 100%;
  margin: 0 auto;
  background: #ffffff;
  min-height: 800px;
}
.mobile-container {
  max-width: 550px !important;
}
.spinner {
  position: absolute;
  top: 20%;
  left: calc(50% - 50px);
}
ul {
  padding-left: 20px !important;
}
img {
  width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  &.ProseMirror-selectednode {
    outline: 3px solid #68cef8;
  }
}
.custom-image-small {
  max-width: 200px;
}
.custom-image-medium {
  max-width: 500px;
}
.custom-image-large {
  max-width: 100%;
}
.custom-image-float-none {
  float: none;
}
.custom-image-float-left {
  float: left;
}
.custom-image-float-right {
  float: right;
}
.purple {
  font-family: 'Yanone Kaffeesatz', sans-serif !important;
  font-size: 1.2rem !important;
  .navbar {
    background: #8F0B5D;
    color: white !important;
    margin-bottom: 15px !important;
  }
  input[type="radio"] {
    margin-right: 10px !important;
  }
  .btn-secondary {
    background: #870861 !important;
  }
  .btn-dark {
    background: #8F0B5D !important;
  }
}
h1 {
  font-weight: bolder;
  font-size: 2rem;
}
h2 {
  font-weight: bold;
  font-size: 1.6rem;
}
h3 {
  font-weight: normal;
  font-size: 1.3rem;
}
blockquote {
  display: block;
  padding-left: 1rem;
  margin: 1rem 0;
  border-left: 2px solid rgba(13, 13, 13, 0.1);
}
ul {
  list-style-type: disc;
}

.var-img {
    width: 250px!important;
    height: 85px!important;
    margin: 0!important;
  }

.menu-list {
  list-style: none;
  padding-left: 0!important;
}

ol {
  padding-left: 20px !important;
}
p {
  min-height: 1rem !important;
}

.app-header {
  border-bottom: 1px solid #dedede;
  .navbar {
    &.logo-right {
      flex-direction: row-reverse;
    }
    .navbar-start {
      margin-right: unset !important;
    }
  }
}

.app-sidebar {
  border-right: 1px solid #dedede;
  padding-left: 1.5rem;
  padding-top: 2rem;
  position: relative;
  .sidebar-content {
    box-shadow: none;
    background-color: transparent !important;
    width: 100%;
  }
  .sidebar-toggle-btn {
    width: 2rem !important;
    padding: 0;
    font-size: 1rem;
    margin: 0;
    margin-bottom: 0.25rem;
    &.sidebar-collapsed {
      position: fixed;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      top: 75px;
      left: 0;
      z-index: 999999;
    }
  }
}

.app-loader {
  margin: 1rem;
}

.app-main-section {
  .app-content {
    &.sidebar-collapsed {
      padding-left: 2.5rem;
    }
  }
}

.preview-frame {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
}

.app-footer {
  margin-top: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #dedede;
}

.b-table {
  font-size: 13px;
  .pagination-list {
    margin-top: 0;
    li {
      margin-top: 0;
    }
  }
}

.b-table tr:hover {
  background: #dedede;
  cursor: pointer;
}

.table thead {
  background: #dedede;
}

.table input {
  padding: 2px 5px!important;
  line-height: 20px!important;
  font-size: 13px!important;
}

.main-logo {
  max-width: 250px!important;
  &.is-svg {
    min-width: 80px;
  }
}

.error-navigator {
  position: fixed;
  top: 0;
  right: 50%;
  left: 50%;
  z-index: 999999;
  .steps {
    font-size: 1rem;
  }
  .tag {
    border-radius: 0;
  }
  .button {
    height: 1.5rem;
  }
  .button-left {
    border-radius: 0.25rem 0 0 0.25rem !important;
  }
  .button-right {
    border-radius: 0 0.25rem 0.25rem 0 !important;
  }
}

@media screen and (max-width: 1023px) {
  .app-header {
    .navbar {
      &.logo-right {
        flex-direction: row;
        .navbar-brand {
          flex-direction: row-reverse;
        }
      }
    }
  }
}

@import "./../assets/themes/gothaer.scss";
@import "./../assets/themes/krose.scss";
@import "./../assets/themes/nuernberger.scss";
@import "./../assets/themes/vhv.scss";
@import "./../assets/themes/tas.scss";
@import "./../assets/themes/nuclicore";
@import "./../assets/themes/axa";
@import "./../assets/themes/wena.scss";
@import "./../assets/themes/meneks.scss";
@import "./../assets/themes/zurich.scss";
@import "./../assets/themes/signalIduna.scss";
@import "./../assets/themes/lta.scss";
@import "./../assets/themes/lta-wide.scss";
</style>
