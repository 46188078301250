import { set } from '@vue/composition-api';
import { pinia } from '@/plugins/pinia';
import { getExpressionParser, createConditionChecker } from '@/lib/nuclicore-core';
import { useVariableStore } from '@/store/variableStore';

const variableStore = useVariableStore(pinia);

const expressionParser = getExpressionParser();
const conditionChecker = createConditionChecker();

export default new class FormRenderer {
    async check(data, values) {
        const fieldsToProcess = [];
        for (let section of data.sections) {
            set(section, 'show', !!(await conditionChecker.isSatisfied(section.condition)));
            for (const column of section.fields) {
                for (const field of column) {
                    if (field.type === 'grid') {
                        const cellElements = field?.properties?.basic?.cells?.data?.flatMap(row => row.filter(
                            cell => cell?.properties?.basic?.element?.type
                        ));
                        fieldsToProcess.push(
                            ...cellElements.map(
                                cell => this.renderForm(cell.properties.basic.element, values, section.show)
                            )
                        );
                    }
                    fieldsToProcess.push(this.renderForm(field, values, section.show));
                }
            }
        }
        await Promise.all(fieldsToProcess);
        return data;
    }

    /**
     * Parses the raw values of a field and update its values with parsed value
     * @param {object} field 
     * @param {object} values Contains parsed values of all fields
     * @param {boolean} isSectionVisible
     */
    async renderForm(field, values, isSectionVisible = true) {
        set(field, 'show', !!(await conditionChecker.isSatisfied(field.condition)));
        const fieldBasicProperties = field?.properties?.basic;
        const validationProperties = field?.properties?.validation;
        const validationExpressions = validationProperties?.expressions;
        const fieldValidationProperties = field?.properties?.validation;

        if (field.id) {
            const checkFieldValueIgnored = fieldBasicProperties?.isIgnoreHiddenFieldValueDynamic ? (await expressionParser.parse(fieldBasicProperties?.ignoreHiddenFieldValue, 'strip')).value : fieldBasicProperties?.ignoreHiddenFieldValue;
            const isFieldValueIgnored = checkFieldValueIgnored ? !(isSectionVisible && field.show) : false;
            const existingIgnoredValue = variableStore.isVariableIgnored(field.id);
            if (existingIgnoredValue !== isFieldValueIgnored) {
                variableStore.updateVariableById(
                    {
                        type: 'field',
                        value: field.id
                    }, 
                    {
                        isValueIgnored: isFieldValueIgnored
                    }
                );
            }
        }

        if (!fieldBasicProperties) {
            return;
        }

        if (field.type === 'text' || field.type === 'toc') {
            const parsedLinkText = await this.parseDynamicLinks(fieldBasicProperties.rawText, values);
            fieldBasicProperties.text = (await expressionParser.parse(parsedLinkText, 'skip')).value;
            if (fieldBasicProperties.text && fieldBasicProperties.text.includes('{') && fieldBasicProperties.text.includes('}')) {
                fieldBasicProperties.text = fieldBasicProperties.text.replace(/\{([^}]+)\}/g, content => `{${content.replace(/"/g, '')}}`);
                fieldBasicProperties.text = fieldBasicProperties.text
                    .replaceAll('{', '')
                    .replaceAll('}', '');
            }
            const images = fieldBasicProperties.text.match(/(\bdata:image\/png;base64\S+\S+)/ig);
            if (images?.length) {
                images.forEach(image => {
                    fieldBasicProperties.text = fieldBasicProperties.text.replace(image, `<img src="${image}" class="var-img" width="340" height="150"  alt=""/>`);
                });
            }

        } else if (field.type === 'data-table') {

            let data = [];

            fieldBasicProperties.headers = [];
            fieldBasicProperties.data = [];

            for (const row of fieldBasicProperties.columns) {
                const rowExpressions = row?.expressions;

                const label = (await expressionParser.parse(rowExpressions?.label, 'strip')).value || row.label;
                fieldBasicProperties.headers.push({
                    field: label,
                    label,
                    visible: row.visible
                });

                data.push({
                    label,
                    value: (await expressionParser.parse(row.value, 'strip')).value
                });
            }

            if(fieldBasicProperties.defaultFilteringColumns?.length){
                fieldBasicProperties.defaultFilteringColumns = await Promise.all(
                    fieldBasicProperties?.defaultFilteringColumns?.map(async (column) => {
                        if(column.keywords){
                            column.keywords = (await expressionParser.parse(column.keywords, 'strip'))?.value?.replace(/^\{|}$/g, '');
                        }
                        return column;
                    })
                );
            }
              
            let orderedData = [];
            data.forEach((row, index) => {
                let singleRow = {};
                row.value = row.value.replace(/<[^>]*>?/gm, '').trim();
                row.value = row.value.replace('{', '');
                row.value = row.value.replace('}', '');

                const rowValues = row.value.split('",');
                rowValues.forEach((value, i) => {
                    if (typeof singleRow[i] === 'undefined') {
                        singleRow[i] = {};
                    }
                    singleRow[i][row.label] = value.replaceAll('"', '');
                });
                orderedData.push(singleRow);
            });

            let groupedData = [];

            for (let i = 0; i < orderedData.length; i++) {
                for (const order in orderedData[i]) {
                    if (typeof groupedData[order] === 'undefined') {
                        groupedData[order] = [];
                    }
                    groupedData[order].push(orderedData[i][order]);
                }
            }

            groupedData.forEach(d => {
                fieldBasicProperties.data.push(d.reduce(((r, c) => Object.assign(r, c)), {}));
            });

            const fieldExpressions = fieldValidationProperties?.expressions;
            if(fieldBasicProperties?.isDefaultSortingDynamic){
                fieldBasicProperties.hasDefaultSorting = !!(await expressionParser.parse(fieldExpressions.hasDefaultSorting, 'strip')).value;
            }
            if(fieldBasicProperties?.isDefaultFilteringDynamic){
                fieldBasicProperties.hasDefaultFiltering = !!(await expressionParser.parse(fieldExpressions.hasDefaultFiltering, 'strip')).value;
            }

        } else if (field.type === 'e-signature') {
            field.value = values[field.id];
        } else if (field.type === 'payment-gateway') {
            const { payload, serviceProvider } = fieldBasicProperties;
            if (serviceProvider === 'Element') {
                if (payload && Object.keys(payload).length) {
                    fieldBasicProperties.payload = {
                        ...payload,
                        quoteId: payload.quoteId ? (await expressionParser.parse(payload.quoteId, 'strip')).value : '',
                        signedUrl: payload.signedUrl ? (await expressionParser.parse(payload.signedUrl, 'strip')).value : '',
                        environment: payload.environment ? (await expressionParser.parse(payload.environment, 'strip')).value : ''
                    };
                }
            }
        } else if (field.type === 'tariff-comparison') {
            const fieldExpressions = fieldBasicProperties?.expressions;
            if (fieldExpressions?.highlightedTariffText) {
                fieldBasicProperties.highlightedTariffText = (await expressionParser.parse(fieldExpressions.highlightedTariffText, 'strip')).value;
            }
            if (fieldBasicProperties?.tariffs) {
                await Promise.all(fieldBasicProperties.tariffs.map(async tariff => {
                    const tariffExpressions = tariff.expressions;
                    if (tariff.isVisible === undefined) {
                        tariff.isVisible = true;
                    } else if (tariff?.isVisibilityDynamic && tariffExpressions?.isVisible) {
                        tariff.isVisible = (await expressionParser.parse(tariffExpressions.isVisible, 'strip')).value;
                    }
                    if (tariffExpressions?.isHighlighted) {
                        tariff.isHighlighted = (await expressionParser.parse(tariffExpressions.isHighlighted, 'strip')).value;
                    }
                    if (tariffExpressions?.title && tariff?.title) {
                        tariff.title = (await expressionParser.parse(tariffExpressions.title, 'strip')).value;
                    }
                    if (tariffExpressions?.price && tariff?.price) {
                        tariff.price = (await expressionParser.parse(tariffExpressions.price, 'strip')).value;
                    }
                    if (tariffExpressions?.description && tariff?.description) {
                        const parsedLinkText = await this.parseDynamicLinks(tariffExpressions.description, values);
                        tariff.description = (await expressionParser.parse(parsedLinkText, 'skip')).value;
                    }
                    if (tariff.isButtonVisible === undefined) {
                        tariff.isButtonVisible = true;
                    } else if (tariff?.isButtonVisibilityDynamic && tariffExpressions?.isButtonVisible) {
                        tariff.isButtonVisible = (await expressionParser.parse(tariffExpressions.isButtonVisible, 'strip')).value;
                    }
                }));
            }
        } else if (field.type === 'date') {
            const fieldExpressions = fieldBasicProperties?.expressions;
            if (fieldBasicProperties?.isTimePickerToggleDynamic) {
                fieldBasicProperties.hasTimePicker = !!(await expressionParser.parse(fieldExpressions.hasTimePicker, 'strip')).value;
            }
            if(fieldBasicProperties?.isPlaceholderDynamic){
                fieldBasicProperties.placeholder = (await expressionParser.parse(fieldExpressions.placeholder, 'strip')).value;
            }
        } else if (field.type === 'time'){
            const fieldExpressions = fieldBasicProperties?.expressions;
            if(fieldBasicProperties?.is24HourFormatDynamic){
                fieldBasicProperties.is24HourFormat = (await expressionParser.parse(fieldExpressions.is24HourFormat, 'strip')).value;
            }
        } else if (field.type === 'iban') {
            const fieldExpressions = fieldBasicProperties?.expressions;
            if (fieldBasicProperties?.isIbanApiValidationToggleDynamic) {
                fieldBasicProperties.hasIbanApiValidation = !!(await expressionParser.parse(fieldExpressions.hasIbanApiValidation, 'strip')).value;
            }
        } else if(field.type === 'password') {
            const fieldExpressions = fieldBasicProperties?.expressions;
            if (fieldBasicProperties?.isPasswordVisibleDynamic) {
                fieldBasicProperties.isPasswordVisible = !!(await expressionParser.parse(fieldExpressions.isPasswordVisible, 'strip')).value;
            }
        } else if (field.type === 'address'){
            const fieldExpressions = fieldBasicProperties?.expressions;
            if (fieldBasicProperties?.hasStateDynamic) {
                fieldBasicProperties.hasState = !!(await expressionParser.parse(fieldExpressions.hasState, 'strip')).value;
            }
            if (fieldBasicProperties?.hasDistrictDynamic) {
                fieldBasicProperties.hasDistrict = !!(await expressionParser.parse(fieldExpressions.hasDistrict, 'strip')).value;
            }                     
            if (fieldBasicProperties?.hasCountryDynamic) {
                fieldBasicProperties.hasCountry = !!(await expressionParser.parse(fieldExpressions.hasCountry, 'strip')).value;
            }
            if (fieldBasicProperties?.subFieldsProperties?.zipCode && fieldExpressions?.subFields?.zipCode?.maxLength) {
                field.properties.basic.subFieldsProperties.zipCode.maxLength = (await  expressionParser.parse(fieldExpressions.subFields.zipCode.maxLength, 'strip')).value;
            }
        } else if (field.type === 'file-upload'){
            const fieldExpressions = fieldValidationProperties?.expressions;
            if(fieldValidationProperties?.isCustomFormatDynamic){
                fieldValidationProperties.customFormat = (await expressionParser.parse(fieldExpressions.customFormat, 'strip')).value;
            }
            if(fieldValidationProperties?.sizeRestriction){
                fieldValidationProperties.sizeRestriction = (await expressionParser.parse(fieldExpressions.sizeRestriction, 'strip')).value;
            }
        } else if (field.type === 'iframe') {
            fieldBasicProperties.url = (await expressionParser.parse(fieldBasicProperties.url, 'strip')).value;
        }

        if(fieldBasicProperties.isDisabled !== undefined && fieldBasicProperties.isDisabledDynamic){
            fieldBasicProperties.isDisabled = !!(await expressionParser.parse(validationExpressions?.isDisabled, 'strip')).value;
        }

        if (validationProperties) {
            if(validationProperties.isRequired !== undefined && validationProperties.isRequiredDynamic){
                validationProperties.isRequired = !!(await expressionParser.parse(validationExpressions.isRequired, 'strip')).value;
            }
            if(validationProperties.isRequired !== undefined && validationProperties.isRequiredMessageDynamic){
                validationProperties.isRequiredMessage = (await expressionParser.parse(validationExpressions.isRequiredMessage, 'strip')).value;
            }
            if(validationProperties.hasRegex !== undefined && validationProperties.isRegexMessageDynamic){
                validationProperties.hasRegexMessage = (await expressionParser.parse(validationExpressions.hasRegexMessage, 'strip')).value;
            }
            if(validationProperties.message !== undefined && validationProperties.isMessageDynamic){
                validationProperties.message = (await expressionParser.parse(validationExpressions.message, 'strip')).value;
            }
            if(validationProperties.rangeErrorMessage !== undefined && validationProperties.isRangeErrorMessageDynamic){
                validationProperties.rangeErrorMessage = (await expressionParser.parse(validationExpressions.rangeErrorMessage, 'strip')).value;
            }
        }
        
    }

    async parseDynamicLinks (rawValue, values) {
        const linkTag = {
            selector: 'a',
            attribute: 'href'
        };
        const parsedText = await expressionParser.parseElementAttribute(linkTag, rawValue, values);
        return parsedText;
    }
};
